<template>
  <div v-if="shopCategories.length > 0" class="mt-5 active">
    <div
      id="carouselExampleSlidesOnly"
      class="carousel slide"
      data-ride="carousel"
    >
      <div class="carousel-inner">
        <div
          :class="index === 0 ? 'carousel-item active' : 'carousel-item'"
          v-for="(category, index) in shopCategories"
          :key="category.id"
          v-if="isDisplayable(category)"
        >
          <div class="ps-product-list">
            <div class="ps-container">
              <div class="ps-section__header">
                <h3>{{ category.name }}</h3>

                <ul class="ps-section__links">
                  <li
                    class="name-holder"
                    v-for="shop in category.shops"
                    :key="shop.id"
                  >
                    <router-link
                      :to="{ name: 'shop', params: { slug: shop.slug } }"
                      >{{ shop.name }}</router-link
                    >
                  </li>
                </ul>
              </div>

              <div class="ps-section__content">
                <div class="row">
                  <div
                    v-for="product in getProducts(category.shops)"
                    :key="product.id"
                    class="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6"
                  >
                    <div class="ps-product--horizontal ls-horizontal">
                      <div class="ps-product__thumbnail">
                        <router-link
                          :to="{
                            name: 'category_products',
                            params: { slug: product.slug },
                          }"
                        >
                          <img
                            :src="productImage(product)"
                            alt="Product Image"
                          />
                        </router-link>
                      </div>
                      <div class="ps-product__content">
                        <router-link
                          :to="{
                            name: 'category_products',
                            params: { slug: product.slug },
                          }"
                          class="ps-product__title"
                          >{{ product.name }}</router-link
                        >
                        <p class="ps-product__price sale">
                          {{ product.price | toCurrency }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShopProductCard",
  props: {
    active: Boolean,
    shopCategories: Array,
  },
  mounted() {
    // $("#myCarousel").find(".carousel-item").first().addClass("active");
  },
  computed: {},
  methods: {
    isDisplayable(category) {
      if (category.shops) {
        return category.shops.map((shop) => shop.products.length).length > 0;
      }
      return false;
    },
    productImage(product) {
      if (product.hasOwnProperty("images")) {
        if (product.images.length > 0) {
          return product.images[0].path;
        }
      }
    },

    getProducts(shops) {
      let products = [];
      shops.forEach((shop) => {
        shop.products.forEach((product) => {
          // if (product && product.images.length > 0) {
          products.push(product);
          // }
        });
      });

      return products ? products.slice(0, 10) : [];
    },
  },
};
</script>

<style lang="css" scoped>
.active {
  display: block;
}

.hide {
  display: none;
}
.shop-name {
  background-color: white;
  padding: 8px;
  border-radius: 5px;
}
.name-holder {
  width: auto;
  margin-bottom: 20px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .shop-name {
    background-color: white;
    padding: 8px;
    border-radius: 5px;
  }
  /* .name-holder{
    width: 100%;
    margin-bottom: 20px;
  } */
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .shop-name {
    background-color: white;
    padding: 8px;
    border-radius: 5px;
  }
  /* .name-holder{
    width: 100%;
    margin-bottom: 20px;
  } */
}
</style>
