<template>
  <div id="homeCarousel" class="carousel slide">
    <b-carousel
      id="carousel-1"
      :interval="3000"
      controls
      indicators
      img-width="1024"
      img-height="580"
      style="text-shadow: 1px 1px 2px #333;"
    >
      <!-- Text slides with image -->
      <b-carousel-slide
        v-for="slide in slides"
        v-lazy
        :caption="slide.title"
        :text="slide.description"
        :img-src="slide.img"
        alt="Slide Image"
        img-width="1024"
        img-height="580"
      >
        <template #img>
          <img
            class="d-block img-fluid w-100"
            width="1024"
            height="580"
            v-bind:src="slide.img"
            alt="Slide Image"
          /> </template
      ></b-carousel-slide>
    </b-carousel>
  </div>
</template>
<script>
import Vue from "vue";
import { BootstrapVue } from "bootstrap-vue";
Vue.use(BootstrapVue);

export default {
  name: "HomeSlideshow",
  data() {
    return {
      slides: [
        {
          img: "/img/login_new (2).jpg",
          title: "Shop DIRECTLY from Zimbabwe's MERCHANTS",
          description:
            "INSTANT or SCHEDULED pickup or delivery across Zimbabwe",
        },
        {
          img: "/img/greenwood-digital (2).jpg",
          title: "",
          description: "",
        },
        {
          img: "/img/greenwood-payonline.jpg",
          title: "",
          description: "",
        },
        {
          img: "/img/greenwood-medicine.jpg",
          title: "",
          description: "",
        },
        {
          img: "/img/grocery.jpg",
          title: "Find your favorite Groceries, place an order",
          description: "pickup or delivery locally and track it.",
        },
        {
          img: "/img/solar.jpg",
          title: "Find your favorite Solar Panel Installs & Parts",
          description:
            "Place an order, pickup or delivery locally and track it",
        },
        {
          img: "/img/hair-beauty.jpg",
          title: "Find your favorite Hair & Beauty & Fitness",
          description:
            "Place an order, pickup or delivery locally and track it",
        },
        {
          img: "/img/house (2).jpg",
          title: "Find your favorite Home Furniture & Electronics",
          description:
            "Place an order, pickup or delivery locally and track it",
        },
        {
          img: "/img/take (2).jpg",
          title: "Find your favorite Takeaways & Restaurants",
          description:
            "Place an order, pickup or delivery locally and track it",
        },
        {
          img: "/img/movies (2).jpg",
          title: "Find your Entertainment & Movies",
          description:
            "place an order, pickup or delivery locally and track it",
        },
        {
          img: "/img/pharmacists (2).jpg",
          title: " Find your Pharmacies & Health Care",
          description:
            "Place an order, pickup or delivery locally and track it",
        },
        {
          img: "/img/psmi (2).jpg",
          title: "",
          description: "",
        },
        {
          img: "/img/fu (2).jpg",
          title: "Find your Utilities, Funerals & Insurances",
          description:
            "Place an order, pickup or delivery locally and track it",
        },
        {
          img: "/img/vih (2).jpg",
          title: "Find your Vehicle Spares & Tyres",
          description:
            "Place an order, pickup or delivery locally and track it",
        },
        {
          img: "/img/psmi.jpg",
          title: "",
          description: "",
        },
      ],
    };
  },
};
</script>
<style scoped>
.slider-container {
  margin-top: 200px;
}

.carousel-slide-item {
  background-color: #0b230cb5;
  padding: 20px;
  color: white;
  position: absolute;
  top: 0;
  bottom: 200px;
  width: 100%;
  height: 100%;
  /* left: ; change it to 50%, if you want the text only shows in the half part */
  right: 0;
}
@media screen and (max-width: 575px) {
  .carousel-item {
    height: 210px;
  }
}
@media screen and (max-width: 470px) {
  .carousel-item {
    height: 190px;
  }
}
@media screen and (max-width: 575px) {
  .carousel-mobile-image {
    width: 100%;
    object-fit: cover;
  }
}
@media (min-width: 992px) {
  .slider-image {
    height: 600px !important;
  }
}
@media (max-width: 992px) {
  .slider-image {
    height: 600px !important;
  }
}
.slider-image {
  height: 600px !important;
}

@media (max-width: 600px) {
  .slider-image {
    height: 100% !important;
  }
}
</style>
