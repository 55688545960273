var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.shopCategories.length > 0)?_c('div',{staticClass:"mt-5 active"},[_c('div',{staticClass:"carousel slide",attrs:{"id":"carouselExampleSlidesOnly","data-ride":"carousel"}},[_c('div',{staticClass:"carousel-inner"},_vm._l((_vm.shopCategories),function(category,index){return (_vm.isDisplayable(category))?_c('div',{key:category.id,class:index === 0 ? 'carousel-item active' : 'carousel-item'},[_c('div',{staticClass:"ps-product-list"},[_c('div',{staticClass:"ps-container"},[_c('div',{staticClass:"ps-section__header"},[_c('h3',[_vm._v(_vm._s(category.name))]),_c('ul',{staticClass:"ps-section__links"},_vm._l((category.shops),function(shop){return _c('li',{key:shop.id,staticClass:"name-holder"},[_c('router-link',{attrs:{"to":{ name: 'shop', params: { slug: shop.slug } }}},[_vm._v(_vm._s(shop.name))])],1)}),0)]),_c('div',{staticClass:"ps-section__content"},[_c('div',{staticClass:"row"},_vm._l((_vm.getProducts(category.shops)),function(product){return _c('div',{key:product.id,staticClass:"col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6"},[_c('div',{staticClass:"ps-product--horizontal ls-horizontal"},[_c('div',{staticClass:"ps-product__thumbnail"},[_c('router-link',{attrs:{"to":{
                          name: 'category_products',
                          params: { slug: product.slug },
                        }}},[_c('img',{attrs:{"src":_vm.productImage(product),"alt":"Product Image"}})])],1),_c('div',{staticClass:"ps-product__content"},[_c('router-link',{staticClass:"ps-product__title",attrs:{"to":{
                          name: 'category_products',
                          params: { slug: product.slug },
                        }}},[_vm._v(_vm._s(product.name))]),_c('p',{staticClass:"ps-product__price sale"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(product.price))+" ")])],1)])])}),0)])])])]):_vm._e()}),0)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }